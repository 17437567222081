import {
  IonItem,
  IonLabel,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonNote,
  IonCard,
  IonCol
} from '@ionic/react';
import { auth } from '../Firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { FetchedPlant } from '../data/interface';
import './PlantListItem.scss';

interface PlantListItemProps {
  plant: FetchedPlant,
  userid: string
}

const PlantListItem: React.FC<PlantListItemProps> = ({ plant, userid }) => {
  const [user] = useAuthState(auth);
  return (
    <IonCol size="6">
      <IonCard routerLink={`/${userid}/plant/${plant.id}`}>
        <img src={plant.variety?.image_link} className="hp-card-img" />
        <IonCardHeader>
          <IonCardSubtitle>{plant.variety?.name}</IonCardSubtitle>
          <IonCardTitle>{plant.nickname}</IonCardTitle>
        </IonCardHeader>
      </IonCard>
    </IonCol>
   
  );
};

export default PlantListItem;
