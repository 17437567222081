import React, { useEffect, useState } from 'react';
import { IonButton, IonCardSubtitle, IonContent, IonHeader, IonIcon, IonInput, IonSelect, IonSelectOption, IonDatetime, IonItem, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory, useLocation } from "react-router";
import { useForm, Controller } from 'react-hook-form';
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from 'react-router';
import firebase from 'firebase';
import moment from 'moment';

import { trackerDb, auth, logout } from '../../Firebase';

import { Plant, PlantType, PlantVariety } from '../../data/interface';
import { getPlantVarieties } from '../../data/PlantVarieties';
import { getPlantTypes } from '../../data/PlantTypes';

const EditPlant = () => {

    const [user, loading, error] = useAuthState(auth);
    const history = useHistory();
    const location = useLocation();

    const [nickname, setNickname] = useState<string>();
    const [type, setType] = useState<number>(0);
    const [variety, setVariety] = useState<number>(0);
    const [planted, setPlanted] = useState<any>();
    const [germinated, setGerminated] = useState<any>();
    const [returnedTypes, setReturnedTypes] = useState<PlantType[]>([]);
    const [returnedVarieties, setReturnedVarieties] = useState<PlantVariety[]>([]);
    const params = useParams<{ userid:string, id: string }>();

   

    const returnedPlant:any = location.state;
    // console.log(returnedPlant)
    
    

    // let initialValues = {
    //     nickname: nickname,
    //     planted: planted,
    //     germinated: germinated,
    //     variety: variety,
    //     type: type
    // }

    const { register, handleSubmit, control, setValue, getValues, reset, formState: { errors } } = useForm({
        // defaultValues: initialValues,
        mode: "onSubmit",
        
        // reValidateMode: "onChange"
    });

    

    useEffect(() => {

        if (loading) return;
        if (!user) return history.replace("/");


        setNickname(returnedPlant.nickname);
        setType(returnedPlant.type);
        setVariety(returnedPlant.variety);
        setPlanted(moment(returnedPlant.planted.toDate()).format());
        setGerminated(moment(returnedPlant.germinated.toDate()).format());

         

         
    
        const types = getPlantTypes();
        setReturnedTypes(types);

        const varieties = getPlantVarieties();
        setReturnedVarieties(varieties);

        // setTimeout(() => { resetForm(); }, 3000)

        
    }, [user, loading]);

    // function resetForm () {
    //     console.log(returnedPlant.nickname)
    //     reset({
    //         nickname: returnedPlant.nickname,
    //         planted: returnedPlant.planted,
    //         germinated: returnedPlant.germinated,
    //         variety: returnedPlant.variety,
    //         type: returnedPlant.type
    //     })
    // }

    

    // console.log(getValues())

    const onError = (errors:any, e:any) => console.log(e, errors);
	
	const onSubmit = (data: any, e: any) => {

        console.log(data)
        console.log(user?.uid)

        // e.target.reset();

        trackerDb.collection('users').doc(user?.uid).collection('plants').doc(params.id).update({
                nickname: nickname,
                planted: firebase.firestore.Timestamp.fromDate(new Date(planted)),
                germinated: firebase.firestore.Timestamp.fromDate(new Date(germinated)),
                type: type,
                variety: variety,
            })
            .then(() => {
                window.location.pathname = '/' + user?.uid + '/plant/' + params.id;
            })
            .catch(error => {
                console.log('Plant add failed: ', error);
            });
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Edit Plant</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>

				<form onSubmit={ handleSubmit(onSubmit, onError) }>

                    <IonItem>
                        <IonLabel>Plant Type</IonLabel>
                        <Controller
                            render={({ field }) => (
                            <IonSelect
                                placeholder="Select One"
                                value={type}
                                onIonChange={e => {setValue("type", e.detail.value);setType(e.detail.value)}}
                            >
                                {returnedTypes.map(t => 
                                <IonSelectOption key={t.id} value={t.id}>{t.name}</IonSelectOption>
                            )}
                            </IonSelect>
                            )}
                            control={control}
                            defaultValue={returnedPlant.type}
                            name="type"
                            rules={{ required: 'This is a required field' }}
                        />
                    </IonItem>

                    <IonItem>
                        <IonLabel>Plant Variety</IonLabel>
                        <Controller
                            render={({ field }) => (
                            <IonSelect
                                placeholder="Select One"
                                value={variety}
                                onIonChange={e => setValue('variety', e.detail.value)}
                            >
                                {returnedVarieties.map(t => 
                                    <IonSelectOption key={t.id} value={t.id}>{t.name}</IonSelectOption>
                                )}
                            </IonSelect>
                            )}
                            control={control}
                            defaultValue={returnedPlant.variety}
                            name="variety"
                            rules={{ required: 'This is a required field' }}
                        />
                        {/* <IonSelect value={variety} placeholder="Select One" onIonChange={e => {setVariety(e.detail.value);setValue("variety", e.detail.value!)}} {...register("variety")}>
                            {returnedVarieties.map(t => 
                                <IonSelectOption key={t.id} value={t.id}>{t.name}</IonSelectOption>
                            )}
                        </IonSelect> */}
                    </IonItem>

                    <IonItem>
                        <IonLabel>Nickname</IonLabel>
                        <Controller
                            render={({ field }) => (
                            <IonInput
                                placeholder="Enter Input"
                                value={nickname}
                                onIonChange={e => {setValue('nickname', e.detail.value);setNickname(e.detail.value!)}}
                            ></IonInput>
                            )}
                            control={control}
                            // defaultValue={returnedPlant.nickname}
                            name="nickname"
                            rules={{ required: 'This is a required field' }}
                        />
                        {/* <IonInput value={nickname} placeholder="Enter Input" onIonChange={e => {setValue("nickname", e.detail.value!)}} {...register("nickname", { required: true })}></IonInput> */}
                    </IonItem>

                    {errors.nickname && "Nickname is required"}

                    <IonItem>
                        <IonLabel>Planted On</IonLabel>
                        <Controller
                            render={({ field }) => (
                            <IonDatetime
                                placeholder="Enter Input"
                                value={planted}
                                onIonChange={e => {setValue('planted', e.detail.value);setPlanted(e.detail.value!)}}
                            ></IonDatetime>
                            )}
                            control={control}
                            defaultValue={returnedPlant.planted}
                            name="planted"
                            rules={{ required: 'This is a required field' }}
                        />
                        {/* <IonDatetime value={planted} onIonChange={e => {setValue("planted", e.detail.value!)}} {...register("planted")}></IonDatetime> */}
                    </IonItem>

                    <IonItem>
                        <IonLabel>Germinated On</IonLabel>
                        <Controller
                            render={({ field }) => (
                            <IonDatetime
                                placeholder="Enter Input"
                                value={germinated}
                                onIonChange={e => {setValue('germinated', e.detail.value);setGerminated(e.detail.value!)}}
                            ></IonDatetime>
                            )}
                            control={control}
                            defaultValue={returnedPlant.germinated}
                            name="germinated"
                            rules={{ required: 'This is a required field' }}
                        />
                        {/* <IonDatetime value={germinated} onIonChange={e => {setValue("germinated", e.detail.value!)}} {...register("germinated")}></IonDatetime> */}
                    </IonItem>

					<IonButton type="submit" className="ion-margin-top" expand="full">Submit</IonButton>
				</form>
			</IonContent>
		</IonPage>
	);
};

export default EditPlant;