import React, { useEffect, useState } from 'react';
import { IonFabButton, IonButton, IonCardSubtitle, IonContent, IonHeader, IonIcon, IonInput, IonSelect, IonSelectOption, IonDatetime, IonItem, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory } from "react-router";
import { useForm } from 'react-hook-form';
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from 'firebase';
import moment from 'moment';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { trackerDb, auth, logout } from '../../Firebase';

import { PlantType, PlantVariety } from '../../data/interface';

import { getPlantVarieties } from '../../data/PlantVarieties';
import { getPlantTypes } from '../../data/PlantTypes';

const AddPlant: React.FC = () => {

    const [user, loading, error] = useAuthState(auth);
    const history = useHistory();

    const [nickname, setNickname] = useState<string>();
    const [type, setType] = useState<string>();
    const [variety, setVariety] = useState<string>();
    const [planted, setPlanted] = useState<string>();
    const [germinated, setGerminated] = useState<string>();
    const [returnedTypes, setReturnedTypes] = useState<PlantType[]>([]);
    const [returnedVarieties, setReturnedVarieties] = useState<PlantVariety[]>([]);

    const [photo, setPhoto] = useState<string>();

    useEffect(() => {

        if (loading) return;
        if (!user) return history.replace("/");
    
        const types = getPlantTypes();
        setReturnedTypes(types);

        const varieties =getPlantVarieties();
        setReturnedTypes(varieties);

        defineCustomElements(window);
 
    }, [user, loading]);

	const { register, handleSubmit, formState: { errors } } = useForm({
		
		// mode: "onTouched",
		reValidateMode: "onChange"
	});
	
	const onSubmit = (data: any) => {

        trackerDb.collection('users').doc(user?.uid).collection('plants').add({
                nickname: data.nickname,
                planted: firebase.firestore.Timestamp.fromDate(new Date(data.planted)),
                germinated: firebase.firestore.Timestamp.fromDate(new Date(data.germinated)),
                type: trackerDb.doc('types/' + data.type),
                variety: trackerDb.doc('variety/' + data.variety),
            })
            .then(function (docRef) {
                history.push('/' + user?.uid + '/plant/' + docRef.id);
            })
            .catch(error => {
                console.log('Plant add failed: ', error);
            });
	}

    const takePicture = async () => {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: true,
          resultType: CameraResultType.Base64
        });
      
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        const imageBase64 = image.base64String;

        // console.log(image)

        // setPhoto(imageBase64)

        // const base64Data = await photo;
        const fileName = `${new Date().getTime()}.jpeg`;

        console.log(fileName)

        await Filesystem.writeFile({
            path: fileName,
            data: imageBase64!,
            directory: Directory.Data
          });
      
        // Can be set to the src of an image now
        // imageElement.src = imageUrl;

        // setState({
        //     photo: imageUrl
        //     })
      };

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Add Plant</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>

				<form onSubmit={ handleSubmit(onSubmit) }>

                    <IonItem>
                        <IonLabel>Plant Type</IonLabel>
                        <IonSelect value={type} placeholder="Select One" onIonChange={e => setType(e.detail.value)} {...register("type", { required: true })}>
                            {returnedTypes && returnedTypes.map(t => 
                                <IonSelectOption key={t.id} value={t.id}>{t.name}</IonSelectOption>
                            )}
                        </IonSelect>
                    </IonItem>
                    {errors.type && "Plant Type is required"}

                    <IonItem>
                        <IonLabel>Plant Variety</IonLabel>
                        <IonSelect value={variety} placeholder="Select One" onIonChange={e => setVariety(e.detail.value)} {...register("variety")}>
                            {returnedVarieties && returnedVarieties.map(t => 
                                <IonSelectOption key={t.id} value={t.id}>{t.name}</IonSelectOption>
                            )}
                        </IonSelect>
                    </IonItem>

                    <IonItem>
                        <IonLabel>Nickname</IonLabel>
                        <IonInput value={nickname} placeholder="Enter Input" onIonChange={e => setNickname(e.detail.value!)} {...register("nickname", { required: true })}></IonInput>
                    </IonItem>
                    {errors.nickname && "Nickname is required"}

                    <IonItem>
                        <IonLabel>Planted On</IonLabel>
                        <IonDatetime displayFormat="MMMM DD" value={planted} onIonChange={e => setPlanted(e.detail.value!)} {...register("planted")}></IonDatetime>
                    </IonItem>

                    <IonItem>
                        <IonLabel>Germinated On</IonLabel>
                        <IonDatetime displayFormat="MMMM DD" value={germinated} onIonChange={e => setGerminated(e.detail.value!)} {...register("germinated")}></IonDatetime>
                    </IonItem>

                    <IonFabButton color="primary" onClick={() => takePicture()}></IonFabButton>

                    {photo && <img src={photo} />}

					<IonButton type="submit" className="ion-margin-top" expand="full">Submit</IonButton>
				</form>
			</IonContent>
		</IonPage>
	);
};

export default AddPlant;