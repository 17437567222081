import { PlantType } from './interface';

const PlantTypes: PlantType[] = [
  {
    id: 1,
    name: 'Tomato',
    image_link: 'https://www.freepnglogos.com/uploads/tomato-png/tomato-puree-manufacturer-and-supplier-lemonconcentrate-3.png'
  },
  {
    id: 2,
    name: 'Pepper',
    image_link: 'https://www.rareseeds.com/media/amasty/webp/catalog/product/cache/4f71e30e38ffe1b90b59b74efe76a4b8/P/e/Pepper-Anaheim-IMG_7012_jpg.webp'
  },
  {
    id: 3,
    name: 'Eggplant',
    image_link: 'https://www.rareseeds.com/media/amasty/webp/catalog/product/cache/4f71e30e38ffe1b90b59b74efe76a4b8/e/g/eggplant-real-black-lss-dsc_9981_jpg.webp'
  },
  {
    id: 4,
    name: 'Fennel',
    image_link: 'https://i.etsystatic.com/25576875/r/il/32dc9a/2975426422/il_1588xN.2975426422_jcww.jpg'
  },
  {
    id: 4,
    name: 'Pea',
    image_link: 'https://h2.commercev3.net/cdn.gurneys.com/images/800/14809A.jpg'
  },
  {
    id: 5,
    name: 'Greens',
    image_link: 'https://www.gardeningknowhow.com/wp-content/uploads/2021/03/leafy-greens-690x518.jpg'
  },
  {
    id: 6,
    name: 'Cauliflower',
    image_link: 'https://www.johnnyseeds.com/dw/image/v2/BBBW_PRD/on/demandware.static/-/Sites-jss-master/default/dwd08ec6c5/images/products/vegetables/00223_01_amazing.jpg?sw=1800'
  },
  {
    id: 7,
    name: 'Herb',
    image_link: 'https://www.thespruce.com/thmb/_3f2E-2g8uVcAEvINIpMkivy5Q0=/960x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/growing-mint-1402628-crop-35227263e23846c5a52ead04bbb0fbdc-ece6acbe0cf44aad888657dc61b0431c.jpg'
  },
];

export const getPlantTypes = () => PlantTypes;

export const getPlantType = (id: number) => PlantTypes.find(t => t.id === id);