import { PlantVariety } from './interface';

const PlantVarieties: PlantVariety[] = [
  {
    id: 1,
    name: 'Red Robin',
    plantType: 1,
    image_link: 'https://s3.amazonaws.com/cdn.totallytomato.com/images/popup/00612.jpg'
  },
];

export const getPlantVarieties = () => PlantVarieties;

export const getPlantVariety = (id: number) => PlantVarieties.find(v => v.id === id);