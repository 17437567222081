import { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { trackerDb, auth, logout } from '../Firebase';
import Moment from 'react-moment';
import firebase from 'firebase';
import {
  IonBackButton,
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonNote,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';

import { useParams, useHistory } from 'react-router';
import './ViewPlant.scss';

import { Plant } from '../data/interface';
import { getPlantVariety } from '../data/PlantVarieties';
import { getPlantType } from '../data/PlantTypes';
import EditPlant from './plants/EditPlant';

function ViewPlant() {
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const [plant, setPlant] = useState<Plant>();
  const [varietyName, setVarietyName] = useState<string>();
  const [imageLink, setImageLink] = useState("");
  const [typeName, setTypeName] = useState("");

  const params = useParams<{ userid:string, id: string }>();

  useEffect(() => {

    if (user) {
      trackerDb
      .collection("users").doc(user.uid).collection("plants").doc(params.id)
      .get().then(plant => {
        console.log(plant.data())

        const data = plant.data();

        const variety = getPlantVariety(data?.variety);
        const type = getPlantType(data?.type);

        setVarietyName(variety!.name);
        setImageLink(variety!.image_link)
        setTypeName(type!.name);

        setPlant({
          id: plant.id,
          image_link: "",
          nickname: data?.nickname,
          germinated: data?.germinated,
          planted: data?.planted,
          type: data?.type,
          variety: data?.variety
        })
    })
  }
      
    // ((plant) => {

    //   console.log(plant.data())
      
    //     const data = plant.data();

    //     const variety = getPlantVariety(data?.variety);
    //     const type = getPlantType(data?.type);

    //     console.log(data);
    //     console.log(variety)

    //     setVarietyName(variety!.name);
    //     setImageLink(variety!.image_link)
    //     setTypeName(type!.name);

        
    //   });
    
    // trackerDb
    // .collection("users").doc(user?.uid).collection("plants").doc(params.id)
    // .onSnapshot((plant) => {

    //   console.log(plant.data())
      
    //     const data = plant.data();

    //     const variety = getPlantVariety(data?.variety);
    //     const type = getPlantType(data?.type);

    //     console.log(data);
    //     console.log(variety)

    //     setVarietyName(variety!.name);
    //     setImageLink(variety!.image_link)
    //     setTypeName(type!.name);

    //     setPlant({
    //       id: plant.id,
    //       image_link: "",
    //       nickname: data?.nickname,
    //       germinated: data?.germinated,
    //       planted: data?.planted,
    //       type: data?.type,
    //       variety: data?.variety
    //     })
    //   });
      // return () => {
      //   unsubscribe();
      // }
  }, [user, loading]);

function editPlant() {
  history.push({ 
    pathname: '/edit-plant/' + params.id,
    state: {
      nickname: plant?.nickname,
      type: plant?.type,
      variety: plant?.variety,
      planted: plant?.planted,
      germinated: plant?.germinated,
    }
   });
}

  return (
    <IonPage id="view-message-page">
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Plants" defaultHref="/home"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={editPlant}>Edit</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {plant ? (
          <>
            <IonCard>
              <img src={imageLink} className="vp-card-img" />
              <IonCardHeader>
                <IonCardSubtitle>{plant.nickname}</IonCardSubtitle>
                <IonCardTitle>{varietyName} {typeName}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <IonList>
                  <IonItem>
                    <IonLabel>
                      <h2>Planted on</h2>
                      <p><Moment format="MMM D, YYYY">{plant.planted.toDate()}</Moment></p>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      <h2>Germinated on</h2>
                      <p><Moment format="MMM D, YYYY">{plant.germinated.toDate()}</Moment></p>
                    </IonLabel>
                  </IonItem>
                </IonList>
              </IonCardContent>
            </IonCard>
          </>
        ) : (
          <div>Plant not found</div>
        )}
      </IonContent>
    </IonPage>
  );
}

export default ViewPlant;
