import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
    apiKey: "AIzaSyAA65ClCIJF4_qNzIMggeg4YZPTY8QGPgM",
    authDomain: "garden-tracker-b2d26.firebaseapp.com",
    projectId: "garden-tracker-b2d26",
    storageBucket: "garden-tracker-b2d26.appspot.com",
    messagingSenderId: "365244345922",
    appId: "1:365244345922:web:d3d68211056e21f14a07db",
    measurementId: "G-LHFWK9P6VN"
};

const app = firebase.initializeApp(config);
const auth = app.auth();
const db = app.firestore();
const trackerDb = db;

const signInWithEmailAndPassword = async (email: any, password: any) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (err) {
        if (err instanceof Error) {
            console.error(err);
            alert(err.message);
        }
      
    }
  };
  const registerWithEmailAndPassword = async (name: any, email: any, password: any) => {
    try {
      const res = await auth.createUserWithEmailAndPassword(email, password);
      const user = res.user;
      await db.collection("users").add({
        uid: user?.uid,
        name,
        authProvider: "local",
        email,
      });
    } catch (err) {
        if (err instanceof Error) {
            console.error(err);
            alert(err.message);
        }
    }
  };
  const sendPasswordResetEmail = async (email: any) => {
    try {
      await auth.sendPasswordResetEmail(email);
      alert("Password reset link sent!");
    } catch (err) {
        if (err instanceof Error) {
            console.error(err);
            alert(err.message);
        }
    }
  };
  const logout = () => {
    auth.signOut();
  };
  export {
    auth,
    trackerDb,
    signInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordResetEmail,
    logout,
  };