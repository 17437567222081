import PlantListItem from '../components/PlantListItem';
import React, { useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import { trackerDb, auth, logout } from '../Firebase';
import { Plant, PlantVariety, PlantType, FetchedPlant } from '../data/interface';
import { getPlantVariety } from '../data/PlantVarieties';
import { getPlantType } from '../data/PlantTypes';
import {
  IonContent,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter
} from '@ionic/react';
import './Home.scss';

const Home: React.FC = () => {

  const [user, loading, error] = useAuthState(auth);
  const [plants, setPlants] = useState<FetchedPlant[]>([]);
  const [plantVariety, setPlantVariety] = useState<PlantVariety>();
  const [plantType, setPlantType] = useState<PlantType>();
  const [name, setName] = useState("");
  const [uid, setUid] = useState("");
  const history = useHistory();
  
  const fetchUserName = async () => {
    try {
      const query = await trackerDb
        .collection("users")
        .where("uid", "==", auth?.currentUser?.uid)
        .get();
      const data = query.docs[0].data();
      setUid(data.uid);
      setName(data.firstName);
      // await fetchPlants(data.uid);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  
  useEffect(() => {

    if (loading) return;
    if (!user) return history.replace("/");

  const unsubscribe = trackerDb
    .collection("users").doc(user?.uid).collection("plants")
    .onSnapshot(snapshot => {
      const fetchedPlants: FetchedPlant[] = [];
      snapshot.forEach(plant => {
        const data = plant.data();
        fetchedPlants.push({
          id: plant.id,
          image_link: data.variety.image_link,
          nickname: data.nickname,
          germinated: data.germinated,
          planted: data.planted,
          type: getPlantType(data.type),
          variety: getPlantVariety(data.variety),
        })     
      });
      setPlants(fetchedPlants);  
      
      return () => unsubscribe();
    });

  return () => unsubscribe();
  
    
  }, [user, loading]);

  

  useIonViewDidEnter(() => {
    
    
    
  });

  return (
    <IonPage id="home-page">
      <IonHeader>
        Logged in as
        <div>{name}</div>
        <div>{user?.email}</div>
        <button className="dashboard__btn" onClick={logout}>
          Logout
        </button>
        <IonToolbar>
          <IonTitle>Plants</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher> */}

        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              Inbox
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid>
          <IonRow>
            {plants.map(m => <PlantListItem key={m.id} plant={m} userid={user!.uid} />)}
          </IonRow>
        </IonGrid>        
        
        
      </IonContent>
    </IonPage>
  );
};

export default Home;
